import { useEffect, useState } from "react";
import { Dialog, Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Dropdown from "../../dropdown";
import { client } from "../../../sanityClient";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "about" },
  { name: "Contact", href: "contact" },
];

const subItems = [{ name: "Home Secondary", href: "home-secondary" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ sticky = false }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [navbarItem, setNavBarItem] = useState([]);
  async function getContent() {
    const CONTENT_QUERY = `*[_type == "navbar"] {
      _id,
      _type,
      _createdAt,
      _updatedAt,
      title,
      slug,
      ...  
    }
  `;
    try {
      const content = await client.fetch(CONTENT_QUERY);
      return content;
    } catch (error) {
      return new Error("failed to fetch data");
    }
  }

  const simpleItems = navbarItem?.[0]?.items.filter(
    (val) => val?.label !== "Others"
  );

  const itemsWithSelect = navbarItem?.[0]?.items?.filter(
    (ele) => ele?.isSelect
  );

  const selectItem = navbarItem?.[0]?.items.find(
    (val) => val?.label === "Others"
  );

  useEffect(() => {
    getContent()
      .then((content) => {
        console.log(content, "contentcontentcontent");
        setNavBarItem(content);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <header
      className={` z-10 bg-white dark:bg-gray-900 ${sticky ? "sticky top-0" : ""}`}>
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global">
        <div className="flex items-center gap-x-12">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">MagnetoMedia</span>
            <img className=" h-12 w-36" src="/new-logo.svg" alt="" />
          </a>
          <div className="hidden lg:flex lg:gap-x-12">
            {simpleItems?.map((item) => (
              <a
                key={item.label}
                href={item.link}
                className="text-sm font-semibold leading-6 text-primary-blue font-aeonik dark:text-primary-beige">
                {item.label}
              </a>
            ))}
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {itemsWithSelect?.map((ele) => (
            <Dropdown
              title={ele?.label}
              subItems={ele?.nestedNavItem?.map((sub) => ({
                name: sub?.label,
                href: sub?.link,
              }))}
            />
          ))}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex">
          <a
            href="/"
            className="text-sm font-semibold leading-6 text-primary-blue font-aeonik dark:text-primary-beige">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">MagnetoMedia</span>
              <img className="h-8 w-auto" src="/logo.svg" alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation?.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {item.name}
                  </a>
                ))}
                {itemsWithSelect?.map((ele) => (
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          {ele.label}
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {ele?.nestedNavItem?.map((item) => (
                            <Disclosure.Button
                              key={item?.name || item?.label}
                              as="a"
                              href={item?.link || item?.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
