import { client, urlFor } from "../../sanityClient";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import Navbar from "../../components/headers/navbar";
import { useEffect, useState } from "react";

export default function Home({ stickyNavbar = false }) {
  const [navbarItem, setNavBarItem] = useState([]);
  async function getContent() {
    const CONTENT_QUERY = `*[_type == "page"] {
      _id,
      _type,
      _createdAt,
      _updatedAt,
      title,
      slug,
      sections[] {
        _type,
        _type == "heroBlock" => {          
            title,
            subtitle,
            image {
              ...
            },
            cta[] {
              
                label,
                link
              
            }
          
        },
        _type == "textBlock" => {
            title,
        },
        _type == "ctaBlock" => {
          ...
        },
        _type == "imageCarousel" => {
          ...
        },
        _type == "featureBlock" => {
          ...
        },
        _type == "deployFastSection" => {
          ...
        }
      }
    }
  `;
    try {
      const content = await client.fetch(CONTENT_QUERY);
      return content;
    } catch (error) {
      return new Error("failed to fetch data");
    }
  }

  useEffect(() => {
    getContent()
      .then((content) => {
        console.log(content, "homehome");
        setNavBarItem(content);
      })
      .catch((err) => console.error(err));
  }, []);

  const renderIcon = (icon, classes) => {
    switch (icon) {
      case "CloudArrowUpIcon":
        return <CloudArrowUpIcon className={classes} aria-hidden="true" />;
      case "LockClosedIcon":
        return <LockClosedIcon className={classes} aria-hidden="true" />;
      case "ArrowPathIcon":
        return <ArrowPathIcon className={classes} aria-hidden="true" />;
      default:
        return null;
    }
  };

  const sections = navbarItem?.[0]?.sections ?? [];

  const imageCarousel = sections?.find(
    (ele) => ele?._type === "imageCarousel"
  )?.images;

  const featureBlock = sections?.find(
    (val) => val?._type === "featureBlock"
  )?.feature;

  const deployFastSection = navbarItem?.[0]?.sections?.find(
    (val) => val?._type === "deployFastSection"
  );

  const heroBlock = navbarItem?.[0]?.sections?.find(
    (val) => val?._type === "heroBlock"
  );

  return (
    <div className="bg-white dark:bg-gray-900">
      {/* Header */}
      <Navbar sticky={stickyNavbar} />

      {/* Hero section */}
      <div className="relative pt-14">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-primary-blue sm:text-6xl font-aeonik dark:text-primary-beige">
                {/* Digital Performance Accelerators */}
                {heroBlock?.title}
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600 font-aeonik dark:text-primary-beige">
                {/* As an international technology consulting company we are your
                partner for the digital transformation age. Master the change
                with our services and solutions. */}
                {heroBlock?.subtitle}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                {heroBlock?.cta.map((cta) => (
                  <a
                    href="/"
                    key={cta?.label}
                    className="rounded-md bg-primary-orange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-aeonik">
                    {cta?.label}
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-16 flow-root sm:mt-24">
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  // src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                  src={
                    sections?.[1]?.image
                      ? urlFor(sections?.[1]?.image || {}).url()
                      : ""
                  }
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true">
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>

      {/* Logo cloud */}

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto place-items-center grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {imageCarousel?.map((image) => (
            <img
              key={image?._ref}
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={image ? urlFor(image || {}).url() : ""}
              alt="Transistor"
              width={158}
              height={48}
            />
          ))}
        </div>
        <div className="mt-16 flex justify-center">
          <p className="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
            <span className="hidden md:inline font-aeonik dark:text-primary-beige">
              Transistor saves up to $40,000 per year, per employee by working
              with us.
            </span>
            <a href="/" className="font-semibold text-primary-orange">
              <span
                className="absolute inset-0 font-aeonik"
                aria-hidden="true"
              />{" "}
              Read our case study <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      </div>

      {/* Feature section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8 pb-32 sm:pb-56">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-primary-orange font-aeonik">
            {deployFastSection?.mainHeading}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-primary-blue sm:text-4xl font-aeonik dark:text-primary-beige">
            {deployFastSection?.title}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 font-aeonik dark:text-primary-beige">
            {deployFastSection?.description}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {featureBlock?.map((feature) => (
              <div key={feature?._key} className="relative pl-16">
                <dt className="font-semibold leading-7 text-primary-blue dark:text-primary-beige">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-orange">
                    {/* <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    /> */}
                    {renderIcon(feature?.iconName, "h-6 w-6 text-white")}
                  </div>
                  {feature?.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600 font-aeonik dark:text-primary-beige">
                  {feature?.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
