import ContactForm from "../../components/contactForm";
import PanelForm from "../../components/form/PanelForm";
import Navbar from "../../components/headers/navbar";

export default function Contact() {
  return (
    <div className="bg-white">
      <Navbar />
      <div className="mt-10">
        <ContactForm />
      </div>
    </div>
  );
}
