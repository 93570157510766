import React from "react";
import CircleStepperNew from "../stepper/CircleStepperNew";

const RadioOptions = [
  {
    heading: "Vorem ipsum dolor",
    description: "Vorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    heading: "Gorem ipsum dolor ",
    description: "Rorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    heading: "Lorem ipsum dolor ",
    description: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    heading: "Rorem ipsum dolor",
    description: "Horem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const radioButton = (
  <label
    class="relative flex items-center p-3 rounded-full cursor-pointer"
    htmlFor="html">
    <input
      type="radio"
      id="orange-radio"
      name="colored-radio"
      className="w-4 h-4 text-orange-500 bg-gray-100 hover:ring-primary-orange   border-gray-300 checked:selection:bg-primary-orange  focus:ring-primary-orange dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-primary-orange dark:bg-gray-700 dark:border-gray-600"
    />
  </label>
);

export default function ContactForm({ backgroundColor = "#EEE9E2" }) {
  return (
    <div
      className={`max-w-[80%] m-auto rounded-2xl py-20 bg-orange-100 dark:bg-gray-900`}>
      <CircleStepperNew
        content={[
          {
            component: ({ handleNext }) => (
              <div className="flex justify-center">
                <div className="flex flex-col justify-center items-center m-auto">
                  <div className="flex flex-col gap-1">
                    <div className="text-primary-blue text-[40px] text-center not-italic font-bold dark:text-primary-beige font-aeonik">
                      Kontaktformular
                    </div>
                    <div className="text-primary-blue text-center text-2xl not-italic font-medium dark:text-primary-beige font-aeonik">
                      Was ist Ihr Anliegen?
                    </div>
                    <p className="text-primary-orange mt-3 text-center text-sm not-italic font-medium font-aeonik">
                      Schritt 1/3
                    </p>
                  </div>
                  <div className="mt-10 mb-6  flex flex-col gap-4 ">
                    {RadioOptions.map((option, index) => (
                      <div key={index} class="flex justify-center items-center">
                        <div class="">{radioButton}</div>
                        <div class="ml-4">
                          <h2 class="text-primary-blue text-lg not-italic font-normal dark:text-primary-beige font-aeonik">
                            {option.heading}
                          </h2>
                          <p className="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                            {option.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="justify-center flex ">
                    <button
                      onClick={handleNext}
                      className="flex w-40 rounded-[33px] bg-primary-orange h-10 justify-center items-center gap-2.5 px-8 py-0 font-aeonik">
                      Weiter
                    </button>
                  </div>
                </div>
              </div>
            ),
          },
          {
            component: ({ handleNext }) => (
              <div className="flex justify-center">
                <div className="m-auto">
                  <div className="flex flex-col gap-1">
                    <div className="text-primary-blue text-[40px] text-center not-italic font-bold dark:text-primary-beige font-aeonik">
                      Kontaktformular
                    </div>
                    <div className="text-primary-blue text-center text-2xl not-italic font-medium dark:text-primary-beige font-aeonik">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <p className="text-primary-orange mt-3 text-center text-sm not-italic font-medium font-aeonik">
                      Schritt 2/3
                    </p>
                  </div>
                  <div className="mt-10  mb-6   flex flex-col gap-4 ">
                    {RadioOptions.map((option, index) => (
                      <div
                        key={index}
                        class="flex justify-center items-center ">
                        <div class="">{radioButton}</div>
                        <div class="ml-4">
                          <h2 class="text-primary-blue text-lg not-italic font-normal dark:text-primary-beige font-aeonik">
                            {option.heading}
                          </h2>
                          <p className="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                            {option.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="justify-center flex ">
                    <button
                      onClick={handleNext}
                      className="flex w-40 rounded-[33px] bg-primary-orange h-10 justify-center items-center gap-2.5 px-8 py-0 font-aeonik">
                      Weiter
                    </button>
                  </div>
                </div>
              </div>
            ),
          },
          {
            component: ({ handleNext }) => (
              <div className="flex justify-center">
                <div className="m-auto">
                  <div className="flex flex-col gap-1">
                    <div className="text-primary-blue text-[40px] text-center not-italic font-bold dark:text-primary-beige font-aeonik">
                      Kontaktformular
                    </div>
                    <div className="text-primary-blue text-center text-2xl not-italic font-medium dark:text-primary-beige font-aeonik">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <p className="text-primary-orange mt-3 text-center text-sm not-italic font-medium font-aeonik">
                      Schritt 3/3
                    </p>
                  </div>
                  <div className="mt-10  mb-6    min-w-[800px]  flex flex-col gap-6 ">
                    <div class="flex gap-6 w-full  ">
                      <div class="flex flex-col gap-2 w-full  ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Vorname *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex w-full border-0  h-10 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                      <div class="flex flex-col gap-2 w-full ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Nachname *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex h-10 w-full border-0 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                    </div>

                    <div class="flex gap-6 w-full ">
                      <div class="flex flex-col gap-2 w-full  ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Anrede *
                        </label>
                        <select
                          id="username"
                          name="username"
                          class="border border-gray-300 rounded-md px-4 py-2  focus:outline-none focus:border-primary-orange">
                          <option
                            selected
                            class="opacity-5 font-aeonik"
                            value="option1">
                            Bitte wählen ...
                          </option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option>
                        </select>
                      </div>
                      <div class="flex flex-col gap-2 w-full ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          E-mail adresse *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex h-10 w-full border-0 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                    </div>
                    <div class="flex gap-6 w-full ">
                      <div class="flex flex-col gap-2 w-full  ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Unternehmen *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex w-full border-0  h-10 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                      <div class="flex flex-col gap-2 w-full ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Telefonnummer *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex h-10 w-full border-0 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                    </div>
                    <div class="flex gap-6 w-full ">
                      <div class="flex flex-col gap-2 w-full  ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Torem *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex w-full border-0  h-10 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                      <div class="flex flex-col gap-2 w-full ">
                        <label
                          for="username"
                          class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                          Vorem *
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          class="flex h-10 w-full border-0 items-center gap-2.5 self-stretch pl-3 pr-0 py-3 rounded bg-primary-white"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col gap-2 w-full ">
                      <label
                        for="username"
                        class="text-primary-blue text-sm not-italic font-normal dark:text-primary-beige font-aeonik">
                        Vorem *
                      </label>
                      <textarea
                        id="username"
                        name="username"
                        rows="4"
                        class="border border-gray-300 h-[160px]  rounded-md px-4 py-2 focus:outline-none focus:border-primary-orange"></textarea>
                    </div>
                  </div>
                  <div class="inline-flex align-middle ">
                    <label
                      class="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor="check">
                      <input
                        type="checkbox"
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        id="check"
                      />
                      <span class="absolute text-primary-beige transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1">
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      class="text-[color:var(--Primary-Deep-Blue,#000)] text-sm not-italic font-normal justify-center flex items-center dark:text-primary-beige font-aeonik"
                      htmlFor="check">
                      Durch Klicken auf diese schaltfläche stimmen sie unserer
                      Datenschutzerklärung und unseren Nutzungsbedingungen zu.
                    </label>
                  </div>
                  <div className="justify-start flex mt-4 ">
                    <button
                      // onClick={handleNext}
                      className="flex text-primary-beige  w-40 rounded-[33px] bg-primary-orange h-10 justify-center items-center gap-2.5 px-8 py-0 font-aeonik">
                      Absenden
                    </button>
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
    //  </div>
  );
}
