import React from "react";

const steps = [
  { id: 1, name: "Profile", href: "#", status: "complete" },
  { id: 2, name: "Personal", href: "#", status: "current" },
  { id: 3, name: "Alert", href: "#", status: "upcoming" },
];

const tickSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20">
    <path
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M5.714 10.317 10 14.285l4.285-7.142"
    />
  </svg>
);

export default function CircleStepperNew({ content = [] }) {
  const [activeStep, setActiveStep] = React.useState(1);

  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(true);

  const handleNext = () => {
    if (!isLastStep) {
      const next = activeStep + 1;
      setActiveStep(next);
      setIsFirstStep(false);
      if (next === steps.length) {
        setIsLastStep(true);
      } else {
        setIsLastStep(false);
      }
    }
  };

  const handlePrev = () => {
    if (!isFirstStep) {
      const prev = activeStep - 1;
      setActiveStep(prev);
      setIsLastStep(false);
      if (prev === 1) {
        setIsFirstStep(true);
      } else {
        setIsFirstStep(false);
      }
    }
  };

  return (
    <div>
      <ol className="flex justify-between	w-full items-center max-w-[330px] text-xs text-gray-900 font-medium sm:text-base m-auto">
        {steps.map((step, stepIdx) => (
          <>
            {step.id < activeStep ? (
              <li
                className={`flex items-center justify-center bg-primary-orange  rounded-full w-5 h-5 relative text-[color:var(--Primary-Deep-Blue,#002F64)] text-center text-sm font-normal `}>
                <div className="absolute top-0 left-0 right-0 text-gray-900 dark:text-white -translate-x-2/4 -translate-x-2/4 -translate-y-6 left-2/4 top-0 w-max">
                  {step.name}
                </div>
                <div className="absolute left-0 top-0 ">{tickSvg}</div>
                <div className="w-5 h-5 border-2 border-primary-orange rounded-full flex justify-center items-center mx-auto "></div>
              </li>
            ) : step.id === activeStep ? (
              <li
                className={`flex items-center justify-center w-5 h-5 relative text-[color:var(--Primary-Deep-Blue,#002F64)] text-center text-sm font-normal `}>
                <div className="absolute top-0 left-0 right-0 text-gray-900 dark:text-white -translate-x-2/4 -translate-x-2/4 -translate-y-6 left-2/4 top-0 w-max">
                  {step.name}
                </div>
                <div className="w-5 h-5 bg-gray-50 border-2 border-primary-orange rounded-full flex justify-center items-center mx-auto"></div>
              </li>
            ) : (
              <li
                className={`flex items-center justify-center w-5 h-5 relative text-[color:var(--Primary-Deep-Blue,#002F64)] text-center text-sm font-normal `}>
                <div className="absolute top-0 left-0 right-0 text-gray-900 dark:text-white -translate-x-2/4 -translate-x-2/4 -translate-y-6 left-2/4 top-0 w-max">
                  {step.name}
                </div>
                <div className="w-5 h-5 bg-gray-50 border-2 border-[#FFAF7A] rounded-full flex justify-center items-center mx-auto "></div>
              </li>
            )}
            {stepIdx !== steps.length - 1 && (
              <li
                className={`flex-1 h-[2px] ${stepIdx + 1 < activeStep ? "bg-primary-orange" : "bg-[#FFAF7A]"}`}></li>
            )}
          </>
        ))}
      </ol>

      <div className="mt-8 p-4">
        {content?.[activeStep - 1]?.component({ handlePrev, handleNext })}
      </div>
    </div>
  );
}
