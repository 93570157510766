import './App.css';
import About from './pages/about';
import Home from './pages/home';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import HomeSecondary from './pages/home-secondary';
import Contact from './pages/contact';

// Navigation for app..
const router = createBrowserRouter([
  {
    path: "/",
    Component: Home
  },
  {
    path: "about",
    Component: About
  },
  {
    path: "contact",
    Component: Contact
  },
  {
    path: "home-secondary",
    Component: HomeSecondary
  },
]);

function App() {

  

  return (
    <RouterProvider router={router}>
      <Home />
    </RouterProvider>
  );
}

export default App;
